import { OFFER_ALL_STATUSES } from 'src/constants';
import * as yup from 'yup';

const latitude = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,20})?))$/;
const longitude =
  /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,20})?))$/;

const phoneRegExp = /^\d{3}-\d{3}-\d{4}$/;
export const CreateBusinessSchema = yup.object({
  id: yup.string(),
  name: yup.string().min(2).max(255).required(),
  description: yup.string().min(16).max(1000).required(),
  locations: yup.array(
    yup.object({
      id: yup.string(),
      title: yup
        .string()
        .min(2, 'title must be at least 2 characters')
        .max(60, 'title must be less than 60 characters')
        .required(),
      latitude: yup.string().matches(latitude, 'Enter a valid latitude'),
      longitude: yup.string().matches(longitude, 'Enter a valid longitude'),
    })
  ),
  addresses: yup.array(
    yup.object({
      id: yup.string(),
      address: yup
        .string()
        .min(3, 'address must be at least 3 characters')
        .max(255, 'address must be less than 255 characters')
        .required(),
      city: yup
        .string()
        .min(3, 'city must be at least 3 characters')
        .max(255, 'city must be less than 255 characters')
        .required(),
      state: yup.string().max(255).optional(),
      zip: yup
        .string()
        .min(5, 'zip must be 5 characters')
        .max(5, 'zip must be 5 characters')
        .required(),
      countryId: yup.string().required('country is a required filed'),
    })
  ),
});

export const UpdateBusinessAccountSchema = yup.object({
  name: yup
    .string()
    .min(2, 'business name must be at least 2 characters')
    .max(255, 'business name must be less than 255 characters')
    .required(),
  description: yup
    .string()
    .min(16, 'business description must be at least 16 characters')
    .max(1000, 'business description must be less than 1000 characters')
    .required(),
  address: yup
    .string()
    .min(3, 'street address must be at least 3 characters')
    .max(255, 'street address must be less than 255 characters')
    .required(),
  city: yup
    .string()
    .min(3, 'city must be at least 3 characters')
    .max(255, 'city must be less than 255 characters')
    .required(),
  state: yup.string().max(255).optional(),
  zip: yup
    .string()
    .min(5, 'zip must be 5 characters')
    .max(5, 'zip must be 5 characters')
    .required(),
  countryId: yup.string().required('country is a required filed'),
});

export const RegisterBusinessInfoSchema = yup.object({
  id: yup.string(),
  name: yup
    .string()
    .min(2, 'business name must be at least 2 characters')
    .max(255, 'business name must be less than 255 characters')
    .required(),
  description: yup
    .string()
    .min(16, 'business description must be at least 16 characters')
    .max(1000, 'business description must be less than 1000 characters')
    .required(),
  addresses: yup.array(
    yup.object({
      id: yup.string(),
      address: yup
        .string()
        .min(3, 'street address must be at least 3 characters')
        .max(255, 'street address must be less than 255 characters')
        .required(),
      city: yup
        .string()
        .min(3, 'city must be at least 3 characters')
        .max(255, 'city must be less than 255 characters')
        .required(),
      state: yup.string().max(255).optional(),
      zip: yup
        .string()
        .min(5, 'zip must be 5 characters')
        .max(5, 'zip must be 5 characters')
        .required(),
      countryId: yup.string().required('country is a required filed'),
    })
  ),
});

export const RegisterBusinessSchema = yup.object({
  email: yup.string().email('Email format is not correct').required(),
});

export const SignInAmdinSchema = yup.object({
  email: yup.string().email('Email format is not correct').required(),
  password: yup.string().max(64).required(),
});

export const EditBusinessBasicInfoSchema = yup.object({
  id: yup.string().required(),
  name: yup.string().min(2).max(255).required(),
  description: yup.string().min(16).max(1000).required(),
});

export const EditBusinessAddressSchema = yup.object({
  address: yup.string().min(3).max(255).required(),
  city: yup.string().min(3).max(255).required(),
  state: yup.string().max(255).optional(),
  zip: yup.string().min(5).max(5).required(),
  countryId: yup.string().required('country is a required filed'),
});

export const UpdateLocationSchema = yup.object({
  title: yup.string().min(2).max(60).required(),
  latitude: yup.string().matches(latitude, 'Enter a valid latitude'),
  longitude: yup.string().matches(longitude, 'Enter a valid longitude'),
});

export const CreateLocationSchema = yup.object({
  id: yup.string(),
  title: yup.string().min(2).max(60).required(),
  latitude: yup.string().matches(latitude, 'Enter a valid latitude'),
  longitude: yup.string().matches(longitude, 'Enter a valid longitude'),
});

export const CreateEditPinSchema = yup
  .object({
    id: yup.string(),
    name: yup.string().min(2).max(60).required(),
    address: yup.string().min(3).max(256).required(),
    notes: yup.string().max(1024).optional(),
    type: yup.string().required(),
    latitude: yup.string().matches(latitude, 'Enter a valid latitude'),
    longitude: yup.string().matches(longitude, 'Enter a valid longitude'),
  })
  .shape(
    {
      phone: yup.string().when('phone', {
        is: (value: string) => value?.length,
        then: (rule) => rule.matches(phoneRegExp, 'Phone number is not valid, mask: xxx-xxx-xxxx'),
      }),
    },
    [['phone', 'phone']]
  );

const MIN_BATCH_SIZE = 2; // for Debug

export const CreateBatchOfBonesSchema = yup
  .object({
    name: yup.string().min(2).max(60).required(),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
    offerId: yup.string().optional(),
    locationIds: yup
      .array()
      .min(1, 'please select at least one item')
      .required('location is a required field'),
    message: yup.string().min(16).max(255).required(),
    totalBones: yup
      .number()
      .typeError('total bones must be greater than or equal to 50')
      .min(MIN_BATCH_SIZE, `total bones must be greater than or equal to ${MIN_BATCH_SIZE}`)
      .max(100000, 'total bones must be less than or equal to 100000')
      .required(),
  })
  .shape(
    {
      promoText: yup
        .string()
        .nullable()
        .notRequired()
        .when('promoText', {
          is: (value: string) => value?.length,
          then: (rule) =>
            rule
              .min(16, 'promo text must be at least 16 characters')
              .max(255, 'promo text must be at most 255 characters'),
        }),
    },
    [['promoText', 'promoText']]
  );

export const EditCampaignSchema = yup.object({
  name: yup.string().min(2).max(60).required(),
  startDate: yup.string().optional(),
  endDate: yup.string().required(),
});

export const EditCampaignContentSchema = yup
  .object({
    message: yup.string().min(16).max(255).required(),
    offerId: yup.string().optional(),
  })
  .shape(
    {
      promoText: yup
        .string()
        .nullable()
        .notRequired()
        .when('promoText', {
          is: (value: string) => value?.length,
          then: (rule) =>
            rule
              .min(16, 'promo text must be at least 16 characters')
              .max(255, 'promo text must be at most 255 characters'),
        }),
    },
    [['promoText', 'promoText']]
  );

export const CreateOfferSchema = yup
  .object({
    id: yup.string(),
    banner: yup.string().required(),
    title: yup.string().min(1).max(60).required(),
    shortDescription: yup.string().min(1).max(60).required(),
    description: yup.string().min(1).max(1000).required(),
    category: yup.string().required('category is a required field'),
    type: yup.string().required('type is a required field'),
    discountType: yup.string().required('discount type is a required field'),
  })
  .shape({
    amount: yup.number().when(['category'], {
      is: (category: any) => category === OFFER_ALL_STATUSES.DISCOUNT_AMOUNT,
      then: yup
        .number()
        .typeError('amount must be greater than or equal to 5')
        .min(5, 'amount must be greater than or equal to 5')
        .max(100000, 'amount must be less than or equal to 100000')
        .required(),
      otherwise: yup.number().notRequired(),
    }),
    discountPercentage: yup.number().when(['category'], {
      is: (category: any) => category === OFFER_ALL_STATUSES.PERCENTAGE,
      then: yup
        .number()
        .typeError('percentage must be greater than or equal to 5')
        .min(5, 'percentage must be greater than or equal to 5')
        .max(100, 'percentage must be less than or equal to 100')
        .required(),
      otherwise: yup.number().notRequired(),
    }),
    averageOrderValue: yup.number().when(['category'], {
      is: (category: any) => category === OFFER_ALL_STATUSES.PERCENTAGE,
      then: yup
        .number()
        .typeError('average order value must be greater than or equal to 5')
        .min(5, 'average order value must be greater than or equal to 5')
        .max(100000, 'average order value must be less than or equal to 100000')
        .required(),
      otherwise: yup.number().notRequired(),
    }),
    retailPrice: yup.number().when(['category'], {
      is: (category: any) => category === OFFER_ALL_STATUSES.BUY_ONE_GET_ONE_FREE,
      then: yup
        .number()
        .typeError('retail price must be greater than or equal to 5')
        .min(5, 'retail price must be greater than or equal to 5')
        .max(100000, 'retail price must be less than or equal to 100000')
        .required(),
      otherwise: yup.number().notRequired(),
    }),
    totalOffersLimit: yup.number().when(['discountType'], {
      is: (discountType: any) => discountType === OFFER_ALL_STATUSES.GENERATE_CODES,
      then: yup.number().typeError('limit must be greater than or equal to 1').min(1).required(),
      otherwise: yup.number().notRequired(),
    }),
    websiteUrl: yup
      .string()
      .url('please enter valid URL!')
      .when(['type'], {
        is: (type: any) => type === OFFER_ALL_STATUSES.PROMO_CODE,
        otherwise: yup.string().notRequired(),
      }),
  });

export const EditOfferSchema = yup
  .object({
    banner: yup.string().required(),
    title: yup.string().min(1).max(60).required(),
    shortDescription: yup.string().min(1).max(60).required(),
    description: yup.string().min(1).max(1000).required(),
    category: yup.string().required('category is a required field'),
    type: yup.string().required('type is a required field'),
    discountType: yup.string().required('discount type is a required field'),
  })
  .shape({
    amount: yup.number().when(['category'], {
      is: (category: any) => category === OFFER_ALL_STATUSES.DISCOUNT_AMOUNT,
      then: yup
        .number()
        .typeError('amount must be greater than or equal to 5')
        .min(5, 'amount must be greater than or equal to 5')
        .max(100000, 'amount must be less than or equal to 100000')
        .required(),
      otherwise: yup.number().notRequired(),
    }),
    discountPercentage: yup.number().when(['category'], {
      is: (category: any) => category === OFFER_ALL_STATUSES.PERCENTAGE,
      then: yup
        .number()
        .typeError('percentage must be greater than or equal to 5')
        .min(5, 'percentage must be greater than or equal to 5')
        .max(100, 'percentage must be less than or equal to 100')
        .required(),
      otherwise: yup.number().notRequired(),
    }),
    averageOrderValue: yup.number().when(['category'], {
      is: (category: any) => category === OFFER_ALL_STATUSES.PERCENTAGE,
      then: yup
        .number()
        .typeError('average order value must be greater than or equal to 5')
        .min(5, 'average order value must be greater than or equal to 5')
        .max(100000, 'average order value must be less than or equal to 100000')
        .required(),
      otherwise: yup.number().notRequired(),
    }),
    retailPrice: yup.number().when(['category'], {
      is: (category: any) => category === OFFER_ALL_STATUSES.BUY_ONE_GET_ONE_FREE,
      then: yup
        .number()
        .typeError('retail price must be greater than or equal to 5')
        .min(5, 'retail price must be greater than or equal to 5')
        .max(100000, 'retail price must be less than or equal to 100000')
        .required(),
      otherwise: yup.number().notRequired(),
    }),
    websiteUrl: yup
      .string()
      .url('please enter valid URL!')
      .when(['type'], {
        is: (type: any) => type === OFFER_ALL_STATUSES.PROMO_CODE,
        otherwise: yup.string().notRequired(),
      }),
  });

export const BatchMessageSchema = yup.object({
  message: yup.string().min(16).max(255).required(),
});

export const BatchNotesSchema = yup.object({
  notes: yup.string().min(0).max(255),
});

export const BatchPromoTextSchema = yup.object().shape(
  {
    promoText: yup
      .string()
      .nullable()
      .notRequired()
      .when('promoText', {
        is: (value: string) => value?.length,
        then: (rule) =>
          rule
            .min(16, 'promo text must be at least 16 characters')
            .max(255, 'promo text must be at most 255 characters'),
      }),
  },
  [['promoText', 'promoText']]
);

export const TopupSchema = yup.object({
  id: yup.string().required(),
  amount: yup
    .number()
    .typeError('amount must be greater than or equal to 100')
    .min(100)
    .max(100000)
    .required(),
});

export const UserTopupSchema = yup.object({
  id: yup.string().required(),
  amount: yup
    .number()
    .typeError('amount must be greater than or equal to 1')
    .min(1)
    .max(100)
    .required(),
});
